<!-- 
    Модальне вікно вибору компанії до номеру клієнта в тікет
-->

<template>

    <dialogmodal>

        <template v-slot:body>

            <div class="mt-4 text-center">
                <h3><b>{{ $t('Оберіть компанію для вказаного номеру') }}?</b></h3>
                <p class="text-muted fs-14 mb-4">{{ $t('phone_num') }}: {{ this.number }}</p>
            </div>

            <div class="mt-2 mb-3">
                <span class="fs-14 text-muted mb-2">Пошук компанії</span>
                <!-- Поле для введення тексту пошуку -->
                <input type="text" v-model="search" class="form-control" />
            </div>

            <div class="row" v-if="this.companies.length">
                <template v-for="item, i in this.filteredCompanies" :key="i">
                    <div class="col-lg-6" 
                        @click="send(item)"
                    >
                        <div class="card card-body-success" >
                            <div class="d-flex mb-4 align-items-center" >
                                <div class="flex-grow-1" >
                                    <h5 class="card-title mb-1 text-white fs-18"  style="word-break: break-all;">{{ item.companyName }}</h5>
                                    <p 
                                        :class="`text-white mb-0 fs-12 ${item.more.companyServer ? mutateCompServer(item.more.companyServer).color : ''}`"
                                    >
                                        {{ item.more.companyServer == 's-1' || item.more.companyServer == 'online-2' ? this.$t('OldAdmin') : this.$t('NewAdmin_panel') }}
                                    </p>
                                </div>
                            </div>
                            <h5 class="fs-13 " >[ xn-l3h ]</h5>
                            <!---->
                        </div>
                    </div>
                </template>
                <!-- <div class="col-5" v-for="items, i in this.companies" :key="i">
                    <button 
                        class="btn " 
                        :class="items.more.companyServer == 's-1' || items.more.companyServer == 'online-2' ? 'btn-success' : 'btn-warning'" 
                        @click="send(items)"
                    >
                        {{ items.companyName }} - {{ items.more.companyServer == 's-1' || items.more.companyServer == 'online-2' ? this.$t('OldAdmin') : this.$t('NewAdmin_panel') }}
                    </button>
                </div> -->
            </div>

            <div class="row" v-else style="display: grid; justify-items: center;">
                <h5>
                    <b>{{ $t('noCompaniesOrContactNumb') }}?</b>
                </h5>

                <div class="col-sm-auto" style="justify-self: center; margin-top: 15px">
                    <!-- відправляємо фейк-об'єкт, якщо компаній нема або нема дозволів у юзера, що створює тікет -->
                    <button class="btn btn-danger" @click="this.send({companyId: 'none', companyName: 'none'})">{{ this.$t('Add') }}</button> 
                </div>
                
            </div>
        </template>

        <template v-slot:footer-bottom>
            <div class="hstack gap-2 justify-content-center remove">
                <button class="btn btn-link link-success fw-medium text-decoration-none" @click="$emit('close')">
                    <i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}
                </button>
                <button class="btn bg-soft-danger link-danger fw-medium text-decoration-none" @click="$emit('close')">
                    {{ $t('Додати тільки номер') }}
                </button>
            </div>
        </template>

    </dialogmodal>

</template>

<script>
// import dialogmodal from '@/components/modal/modalsave.vue'
import dialogmodal from '@/components/modal/new/modal-xl.vue'
import { mutateCompServer } from '@/usabilityScripts/globalMutate.js'
import { SuperAdmin } from "@/API.js"

let apiAdmin = new SuperAdmin()

export default {
    props: ['number'],
    components: {
        dialogmodal
    },
    data(){
        return{
            companyName: "",
            companyId: '',
            companies: [],
            search: ''
        }
    },
    created(){
        apiAdmin.getCard(this.number).then(result => {
            if(result.status == 'done'){
                result.data.сompanies?.forEach(el => {
                    this.companies.push(el)
                })
            }
        })
    },
    methods: {
        send(e){
            this.$emit('choseComp', {
                companyId: e.companyId, 
                companyName: e.companyName, 
                number: this.number,
                server: 'xn-l3h'
            })
        },
        mutateCompServer(e){
            // Метод для форматування сервера компанії
            return mutateCompServer(e)
        }
    },
    mounted(){
    },
    computed: {
        filteredCompanies: function() {
            // Фільтрація компаній за текстом пошуку
            return this.companies?.filter(item => {
                return this.search == '' || item.companyName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.companyId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
            })
        },
    }
}
</script>
<style scoped>
.card-body-success {
    /* margin-right: 10px; */
    /* max-width: 250px; */
    padding: 15px;
    background-color: #009688;
    cursor: pointer;
}
.card-body-danger {
    /* margin-right: 10px; */
    /* max-width: 250px; */
    padding: 15px;
    background-color: #ee664e;
    cursor: pointer;
}

.card-body-primary{
    padding: 15px;
    background-color: #0d6efd !important;
    cursor: pointer;
}
</style>