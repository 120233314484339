<!-- Моадльне вікно створення тікету -->

<template>

    <modal>

        <template v-slot:title>{{ $t('new_ticket') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>

            <b-row >

                <!-- Основне -->
                <div class="preconditions mb-3">
                    <div class="header-line-container">
                        <h5 class="fw-semibold text-uppercase">{{ $t('main') }}</h5>
                    </div>
                </div>

                <b-col lg="12">

                    <div class="mb-3">
                        <!-- Назва тікету -->
                        <h4 class="fs-15">{{ $t('ticket_name') }}</h4>
                        <input type="text" class="form-control" id="text" v-model="form.ticketName" :placeholder="$t('for_example_name_ticket')">
                    </div>

                </b-col>

                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Категорія тікету -->
                        <h4 class="fs-15">{{ $t('Категорія тікету') }}</h4>
                        <select v-model="selectedCategory" @change="updateAvailableTypes" class="form-control">
                            <option v-for="category in typeTicketCategories" :value="category.value" :key="category.value">
                                {{ category.label }}
                            </option>
                        </select>
                    </div>
                </b-col>

                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Тип тікету -->
                        <h4 class="fs-15">{{ $t('ticket_type') }}</h4>
                        <select v-model="selectedType" class="form-control">
                            <option v-for="type in availableTypes" :value="type.value" :key="type.value">
                                {{ type.label }}
                            </option>
                        </select>
                    </div>
                </b-col>

                <b-col lg="6">
                    <!-- Cередовище проблеми -->
                    <h4 class="fs-15">
                        {{ $t('problem_area') }} 
                        <span class="text-muted">({{devProjects.length}})</span>
                    </h4>
                    <Multiselect 
                        v-model="form.problemEnvironment"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="this.devProjects"
                    />
                   
                </b-col>

                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Вибір пріоритету -->
                        <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                            <Multiselect 
                                v-model="form.priority"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.listPriority"
                                :placeholder="$t('priority')"
                            />
                    </div>
                </b-col>

                <b-col lg="12" v-if="form.priority == '3'">
                    <div class="mb-3">
                        <!-- Причина критичності тікету -->
                        <h4 class="fs-15">{{ $t('ReasonsforCriticality') }}</h4>
                        <textarea type="text" class="form-control" v-model="form.reason" id="text"  :placeholder="$t('ReasonsCritPlaceh')"></textarea>
                    </div>
                </b-col>

                <!-- Основне -->
                <div class="preconditions mb-3 mt-2">
                    <div class="header-line-container">
                        <h5 class="fw-semibold text-uppercase">{{ $t('Джерело звернення') }}</h5>
                    </div>
                </div>

                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Номери клієнтів -->
                        <h4 class="fs-15">{{ $t('Джерело') }}</h4>
                        <b-form-select v-model="form.source" :options="sourseList" ></b-form-select>
                    </div>
                </b-col>

                <b-col lg="6" v-if="form.source == '1'">
                    <div class="mb-3">
                        <!-- Номери клієнтів -->
                        <h4 class="fs-15">{{ $t('clients_number') }}</h4>
                        <Multiselect
                            v-model="numbers"
                            mode="tags"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="true"
                            :options="this.frequentNumbers"
                            @change="findComp($event)"
                        />
                    </div>
                    
                    <div v-if="form.userPhones && form.userPhones.length > 0" class="hstack gap-2 flex-wrap mb-3">
                        <button class="btn btn-info" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Обрані клієнти <i class="bx bx-chevron-right"></i></button>
                    </div>

                    <!-- Обрані номера до тікету -->
                    <div class="collapse multi-collapse" id="multiCollapseExample2">
                        <div style="max-height: 215px;overflow: scroll;"> 
                            <ul class="list-group">
                                <template v-for="item, i in form.userPhones" :key="i">
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-xs">
                                                        <div class="avatar-title bg-soft-info text-black rounded">
                                                            #{{ i+1 }}
                                                        </div>
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">{{ item.number }}</h6>
                                                        <span class="text-muted fs-12">{{ item.companyName }} <span class="text-muted"> [ {{ item.server }} ]</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <button class="copy btn btn-soft-danger btn-icon"><i class="bx bx-trash"></i></button>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul> 
                        </div>
                    </div>
                </b-col>

            </b-row>

             <!-- Основне -->
            <div class="preconditions mb-3 mt-2">
                <div class="header-line-container">
                    <h5 class="fw-semibold text-uppercase">{{ $t('desc') }}</h5>
                </div>
            </div>

            <!-- опис -->
            <div class="mb-3">
                <!-- <h4 class="fs-15">{{ $t('desc') }}</h4> -->
                <QuillEditor v-model:content="form.comment" ref="quill" :modules="modules" theme="snow" contentType="html" toolbar="full"/>
            </div>

            <!-- Основне -->
            <div class="preconditions mb-2 mt-4">
                <div class="header-line-container">
                    <h5 class="fw-semibold text-uppercase">{{ $t('Testing') }}</h5>
                </div>
            </div>

            <div class="mt-3">
                <h4 class="fs-15 mb-3">{{ $t('Чи потребує тікет тестування?') }}</h4>
                <b-row>
                    <b-col lg="5">
                        <div class="card bg-light">

                            <!-- Кнопка для видалення активного блоку -->
                            <div class="disabledBlock" v-if="form.isTesting === true">
                                <button 
                                    @click="form.isTesting = false" 
                                    type="button" 
                                    class="btn btn-info mt-3 fs-14 fw-semibold"
                                >
                                    {{ $t('Обрано') }}
                                </button>
                            </div>
                            
                            <!-- Загальна інформація про чек-лист -->
                            <div class="card-body copy m-2" @click="changeIsTesting(true)">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="bx bx-check-circle display-6 text-muted"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h3 class="mb-0 fw-semibold">{{ $t('Так') }}</h3>
                                        <span class="mt-1 fs-12 text-muted" style="display: block;">{{ $t('Тікет спочатку надходить у відділ тестування') }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </b-col>

                    <b-col lg="5">
                        <div class="card bg-light">

                            <!-- Кнопка для видалення активного блоку -->
                            <div class="disabledBlock" v-if="form.isTesting === false">
                                <button 
                                    @click="form.isTesting = false" 
                                    type="button" 
                                    class="btn btn-info mt-3 fs-14 fw-semibold"
                                >
                                    {{ $t('Обрано') }}
                                </button>
                            </div>

                            
                            <div class="card-body copy m-2" @click="changeIsTesting(false)">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="bx bx-x display-6 text-muted"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h3 class="mb-0 fw-semibold">{{ $t('Ні') }}</h3>
                                        <span class="mt-1 fs-12 text-muted" style="display: block;">{{ $t('Тікет надходить у відділ розробки') }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </b-col>

                    <template v-if="form.isTesting == true">

                        <!-- Середовище тестування -->
                        <div class="preconditions mt-3 mb-4">
                            <div class="header-line-container">
                                <h5 class="fw-semibold text-uppercase">{{ $t('TestingEnvironment') }}</h5>
                            </div>
                        </div>

                        <div>

                            <button v-if="checkAddEnvironment == false" @click="checkAddEnvironment = true" type="button" class="btn btn-soft-info mb-3">
                                <span class="icon-on">
                                    <i class="ri-add-line align-bottom me-1"></i> {{ $t('AddEnvironment') }}
                                </span>
                            </button>

                            <div v-if="checkAddEnvironment">
                                <!-- Рядок з вибором опцій -->
                                <b-row >     

                                    <!-- Блок для вибору операційної системи -->
                                    <b-col lg="3">
                                        <h4 class="fs-15">{{ $t('SelectOS') }}</h4>
                                        <b-form-select v-model="selectedOS" :options="listOS" ></b-form-select>
                                    </b-col>

                                    <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
                                    <b-col lg="3" v-if="selectedOS == 'windows'">
                                        <h4 class="fs-15">{{ $t('Вкажіть версію') }}</h4>
                                        <b-form-input v-model="selectedVerOS" />
                                    </b-col>

                                    <!-- Вибір версії застосунку  -->
                                    <b-col lg="3">
                                        <h4 class="fs-15">{{ $t('Вкажіть версію застосунку') }}</h4>
                                        <b-form-input v-model="selectedVesAppBrow" />
                                    </b-col>

                                </b-row>


                            </div>
                        </div>

                        <!-- Додатково по тікету -->
                        <div class="preconditions mt-3 mb-4">
                            <div class="header-line-container">
                                <h5 class="fw-semibold text-uppercase">{{ $t('Додатково') }}</h5>
                            </div>
                        </div>

                        <b-row class="mb-4">
                            <!-- Блок для уточнення чи виникає проблема регулярно -->
                            <b-col lg="4">
                                <h4 class="fs-15">{{ $t('Чи виникає проблема регулярно?') }}</h4>
                                <b-form-select :options="regularityofProblem" ></b-form-select>
                            </b-col>

                        </b-row>

                        
                    </template>

                </b-row>

                <!-- Файли -->
                <files 
                    :obj="files" 
                    :objFiles="files" 
                    @uploadFiles="uploadFiles"
                    @downloadFile="downloadFile"
                    @deleteFile="deleteFile"
                    @getFile="viewFile"
                />

            </div>

            

        </template>

        <template v-slot:footer-bottom>
            <!-- Кнопки додавання та зміни тікету. 
                Є перевірка: 
                    - На наявність perm. Якщо true, то кнопка "Редагування"
            -->
            <button type="button" v-if="Boolean(this.perm) == false" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
            <button type="button" v-else class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button>
        </template>


    </modal>

    <!-- 
        Модалка "Вибір компанії клієнта"
        @close - дія закриття після створення 
        @choseComp - дія, після створення компанії
        :numbers - пропс номерів клієнтів
    -->
    <choiseCompanyClientNewVue 
        v-if="showEnterComp === true" 
        @close="closeWithCheck" 
        @choseComp="addComp"
        :number="this.actualNumber" 
    />
    
</template>

<script>
// import modal from '@/components/modal-small' 
import modal from '@/components/modal/new/modal-xl' 
import choiseCompanyClientNewVue from './choiseCompanyClientNew.vue';
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import files from '@/components/files/index'
import { mutatePhone, mutateProblemEnv, mutateTypeTicket } from '../../usabilityScripts/globalMutate';
import { storeS } from '@/store.js'


export default {
    props: [],
    components: {
        modal,
        Multiselect,
        files,
        choiseCompanyClientNewVue
    },
    data() {
        return {
            form: {
                deadlineId: '',
                accountId: '',
                devices: [],
                userPhones: [],
                ticketName: "",
                priority: '0',
                problemEnvironment: "",
                status: "new",
                type: "",
                comment: "",
                reason: "",
                categoryTicket: "",
                isTesting: true,
                source: ''
            },
            checkAddEnvironment: false,
            selectedOS: 'windows',
            selectedVerOS: '',
            selectedApp: '',
            selectedBrowser: '',
            selectedVesAppBrow: '',
            files: [], // файли
            listOS: [
                { "value": null, "text": 'Оберіть ОС', disabled: true },
                { "value": "windows", "text": "Windows"},
                { "value": "android", "text": "Android"},
                { "value": "ios", "text": "IOS"},
                { "value": "macos", "text": "MacOS"},
            ],
            regularityofProblem: [
                { "value": "1", "text": "Проблема постійна"},
                { "value": "2", "text": "Виникає випадково"},
                { "value": "3", "text": "Важко відповісти"}
            ],
            sourseList: [
                { "value": "1", "text": "Від клієнта"},
                { "value": "2", "text": "Від підтримки/QA"},
                { "value": "3", "text": "Внутрішній аудит"}
            ],
            devProjects: [],
            typeTicket: [
                // Типи тікетів
                {
                    "label": this.$t('problem_to_develop'),
                    "value": "problem"
                },
                {
                    "label": this.$t('wish_to_develop'),
                    "value": "wishes"
                },
                {
                    "label": "Запит на зміну даних",
                    "value": ''
                },
                {
                    "label": "Питання",
                    "value": ''
                },
                {
                    "label": "Скарга",
                    "value": ''
                },
                {
                    "label": "Запит на знижку/акцію",
                    "value": ''
                },
                {
                    "label": "Запит на навчання",
                    "value": ''
                },
                {
                    "label": "Запит на оновлення документації",
                    "value": ''
                },
                {
                    "label": "Запит на інтеграцію",
                    "value": ''
                },
                
                
            ],
            listPriority: [
                {
                    "label": this.$t('Critical'),
                    "value": "3"
                },
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            selectedCategory: "", // Обрана категорія
            selectedType: "", // Обраний тип
            availableTypes: [], // Доступні типи для обраної категорії
            typeTicketCategories: [
                {
                    label: "Технічні питання",
                    value: "technical",
                    types: [
                        {
                            label: this.$t('problem_to_develop'), // Проблема
                            value: "problem"
                        },
                        // {
                        //     label: "Баг",
                        //     value: "bug"
                        // }
                    ]
                },
                {
                    label: "Запити на зміни",
                    value: "change_requests",
                    types: [
                        {
                            label: "Запит на зміну даних",
                            value: "data_change"
                        },
                        {
                            label: "Запит на оновлення документації",
                            value: "documentation_update"
                        }
                    ]
                },
                {
                    label: "Побажання/Ідеї",
                    value: "ideas",
                    types: [
                        {
                            label: this.$t('wish_to_develop'), // Побажання
                            value: "wishes"
                        },
                        {
                            label: "Запит на новий функціонал",
                            value: "new_feature"
                        },
                        {
                            label: "Запит на інтеграцію",
                            value: "integration_request"
                        }
                    ]
                },
                {
                    label: "Клієнтські питання",
                    value: "client_issues",
                    types: [
                        {
                            label: "Питання",
                            value: "question"
                        },
                        {
                            label: "Скарга",
                            value: "complaint"
                        },
                        {
                            label: "Запит на знижку/акцію",
                            value: "discount_request"
                        }
                    ]
                },
                {
                    label: "Навчання та підтримка",
                    value: "training_support",
                    types: [
                        {
                            label: "Запит на навчання",
                            value: "training_request"
                        }
                    ]
                }
            ],
            numbers: [], // номери клієнтів
            frequentNumbers: [], // часті номера
            actualNumber: '', // обраний номер при виборі компанії
            showEnterComp: false, // відобразити вікно вибору компанії до номеру
        }
    },
    created(){
        this.updateAvailableTypes();
        this.changeSelectDevProjects();
    },
    methods: {
        updateAvailableTypes() {
            const category = this.typeTicketCategories.find(cat => cat.value === this.selectedCategory);
            this.availableTypes = category ? category.types : [];
            this.selectedType = ""; // Очистити тип при зміні категорії
        },
        changeSelectDevProjects(){
            // Робимо вигляд проєктів під мультіселект

            this.devProjects = [];
            for(var item in this.developmentProjects){
                var project = this.developmentProjects[item]
                this.devProjects.push({
                    "label": project.projectName,
                    "value": project.projectKey
                })
            }
            
        },
        changeIsTesting(plan){
            // Активовуємо блок тестування

            // if (this.form.isTesting === 0) return; // Якщо план уже активний, нічого не робимо

            // Встановити новий активний план
            this.form.isTesting = plan;
        },
        findComp(e) {
            // подія після додавання номеру на відкриття "Вибору компанії"

            if(this.form.userPhones.length > e.length) { // якщо масив номерів, що запам'товані, більше, то сталось видалення і ми це опрацьовуємо
                this.form.userPhones.forEach((el, index) => {
                    if(e.indexOf(String(el).substr(0,2)) == -1 && e.indexOf(el) == -1) {
                        this.form.userPhones.splice(index, 1)
                    }
                })
            } else { // форматуємо номер та відкриваємо модалку
                this.actualNumber = this.phoneReset(Number(e[e.length - 1]))
                this.showEnterComp = true
            }
        },
        phoneReset(e) {
            // подія модифікації номера для апі
            return mutatePhone(e)
        },
        // додавання компанії
        addComp(e) {
            this.$toast.success(this.$t('company') + ` ${e.companyName}`)
            this.form.userPhones.push(e) // в масив номерів кидаємо компанію з номером, або просто номер
            this.numbers = []
            this.showEnterComp = false
        },
        // якщо вікно просто було закрите, то не зараховуємо номер
        closeWithCheck(a){
            if(a == false) {
                this.showEnterComp = false
            } else {
                this.showEnterComp = false
                this.numbers.pop()
            }
        },
    },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    },
}
</script>
<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Центрування всього контейнера */
    gap: 10px; /* Відступ між заголовком і лініями */
}

.header-line-container h5 {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
}

.header-line-container::after {
    content: ""; /* Псевдоелементи для ліній */
    flex-grow: 1;
    height: 1px; /* Товщина лінії */
    background-color: #ccc; /* Колір лінії */
}

.disabledBlockActive{
    width: 100%;
    height: 100%;
    background: hsl(0deg 0% 84.79% / 65%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.input-wrapper {
  position: relative;
  display: inline-block;
}

input.with-icon {
  padding-right: 24px; /* Додаємо відступ для іконки */
}

.edit-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: gray; /* Колір іконки */
}
</style>